import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User2/Documents/flits-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "vendor-bill"
    }}>{`Vendor Bill`}</h1>
    <p>{`Vendor Bill adalah tanda tagihan kepada perusahaan, dimana pada kegiatan ini terjadi pengakuan Hutang Perusahaan. Vendor Bill ini biasa dibuat berdasarkan Purchase Order yang sudah berstatus “Close”, tetapi dapat juga langsung dibuat dengan mengisi item yang dibeli.`}</p>
    <h2 {...{
      "id": "formulir-vendor-bill"
    }}>{`Formulir Vendor Bill`}</h2>
    <img src='/public/img/Bill/Form Bill.png' />
    <h3 {...{
      "id": "kolom-kolom-pada-vendor-bill"
    }}>{`Kolom-kolom Pada Vendor Bill`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Vendor`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field Pilih Nama Vendor`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl. Bill`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk tanggal Dokumen Vendor Bill`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Syarat Pembayaran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan jumlah hari sebelum jatuh tempo`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl Jatuh Tempo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field otomatis terisi sesuai perhitungan Tgl. Bill ditambah dengan Syarat Pembayaran`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "detail-tabel-vendor-bill"
    }}>{`Detail Tabel Vendor Bill`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Item`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menuliskan nama barang yang dibeli`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Akun`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memilih CoA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`No. Polisi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memilih kendaraan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Qty`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk mengisi jumlah barang yang dibeli`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Harga Satuan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Harga per satuan barang`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pajak`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan Pajak untuk setiap barang / item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Subtotal`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Perhitungan total tagihan untuk setiap item sebelum dikenakan Pajak`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "informasi-lainnya-pada-vendor-bill"
    }}>{`Informasi Lainnya Pada Vendor Bill`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Memo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambah keterangan atau catatan pada Bill`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Subtotal`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Otomatis terisi sesuai total amount sebelum pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pajak`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Otomatis terisi sesuai total pajak dari semua item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Total`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Otomatis terisi sesuai total amount setelah ditambahkan pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Sisa Tagihan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jumlah tagihan yang masih belum dibayar oleh Perusahaan`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "list-vendor-bill"
    }}>{`List Vendor Bill`}</h2>
    <p>{`Dokumen Bill yang telah dibuat pada database, akan disimpan atau direkap dalam satu tabel yang disebut List Vendor Bill. List ini juga bisa dikatakan sebagai Overview daripada Vendor Bill`}</p>
    <p>{`List Vendor Bill dapat diakses dengan cara klik Hutamg Pada `}<strong parentName="p">{`Side Menu > Vendor Bill`}</strong></p>
    <img src='/public/img/Bill/List Bill.png' />
    <p>{`Tampilan List Vendor Bill terbagi menjadi dua bagian yaitu :`}</p>
    <p><strong parentName="p">{`Tombol menu pada List Bill, terdiri dari :`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Buat`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tombol untuk membuat dokumen Bill baru`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Import`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tombol untuk menginputkan dokumen Bill secara massal dalam sekali import`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Filter`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Terdapat dua jenis filter yaitu by Status dan by Periode`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Search bar`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field yang dapat digunakan untuk melakukan pencarian terhadap dokumen yang diinginkan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Atur Halaman`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Navigasi Next or Previous Page`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`List Customer Invoice Detail, terdiri dari :`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Bill#`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nomor Dokumen Bill`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Vendor`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nama vendor yang tertera pada Bill`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl Bill`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tanggal diterbitkannya Bill`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl Jatuh Tempo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tanggal Jatuh Tempo`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Referensi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Catatan tambahan untuk dokumen Bill`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`DPP`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total tagihan sebelum pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pajak`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total Pajak pada Bill`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Total`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jumlah tagihan pada Bill setelah dikenakan Pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Sisa Tagihan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jumlah tagihan yang belum dilakukan pembayaran`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Status`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Terdiri dari empat status, yakni :`}<ul><li><strong parentName="td">{`Draft`}</strong><div>{`Dokumen bisa dilakukan perubahan`}</div></li><li><strong parentName="td">{`Open`}</strong><div>{`Dokumen yang sudah dilakukan Approval dan dapat dilakukan pembayaran`}</div></li><li><strong parentName="td">{`Paid`}</strong><div>{`Dokumen telah dibayar Lunas`}</div></li><li><strong parentName="td">{`Cancelled`}</strong><div>{`Dokumen dibatalkan setelah dilakukan Approval`}</div></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Ikon Warning ( `}<img src='/public/img/SO/warning.png' style={{
                "margin": "0"
              }} />{` )`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ikon tersebut muncul ketika Bill telah melewati tanggal jatuh temponya`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "membuat-vendor-bill"
    }}>{`Membuat Vendor Bill`}</h2>
    <p>{`Berikut merupakan langkah-langkah dalam pembuatan dokumen Vendor Bill yang dapat digunakan sebagai transaksi jual beli kepada Vendor :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Vendor Bill dengan klik Hutang Pada `}<strong parentName="p">{`Side Menu > Vendor Bill >`}</strong>{` Pada list Vendor Bill klik Tombol `}<strong parentName="p">{`Create`}</strong>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Isi Formulir Vendor Bill seperti gambar berikut :`}</p>
  <img src='/public/img/Bill/Bikin Bill Detail.png' />
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Pilih Nama Vendor`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Isi Tanggal diterbitkannya Bill`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Syarat Pembayaraan (Jumlah hari sebelum Jatuh tempo)`}</p>
            <img src='/public/img/Bill/Bikin Bill.png' />
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Nama barang yang akan dibuatkan Bill`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih CoA untuk setiap Item`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih kendaraan jika item yang dipilih ada bersangkutan dengan salah satu kendaraan`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan jumlah barang yang dibeli`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Harga satuan tiap item`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Tambahkan Pajak jika diperlukan`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field diisi sesuai dengan pesanan, klik tombol Save untuk menyimpan dokumen Bill yang telah diisi sebelumnya. Pada saat ini status Dokumen adalah “Draft”. (Dokumen masih perlu Approval untuk dapat dilanjutkan ke langkah berikutnya)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Untuk melakukan Approval user dapat klik tombol Confirm pada halaman detail dokumen. Dengan ini status dokumen Bill menjadi “Open”.`}</p>
        <p parentName="li">{`  `}<strong parentName="p">{`Catatan`}</strong>{` : Ketika Dokumen Vendor Bill ini di Confirm maka akan otomatis membentuk Jurnal Tipe Pembelian dengan status Posted.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "membatalkan-dokumen-setelah-approval"
    }}>{`Membatalkan Dokumen Setelah Approval`}</h2>
    <p>{`Pembatalan dokumen Vendor Bill yang dimaksud disini adalah tindakan yang membuat dokumen tersebut tidak dapat lagi dipakai sebagai dokumen transaksi tanpa menghapus dokumen tersebut. Sehingga dokumen Vendor Bill tersebut masih tetap bisa ditelusuri pada List Vendor Bill.`}</p>
    <p>{`Pembatalan dokumen Vendor Bill dapat dilakukan melalui langkah-langkah berikut :`}</p>
    <ol>
      <li parentName="ol">{`Buka List Vendor Bill ( Klik Hutang pada side menu > Vendor Bill )`}</li>
      <li parentName="ol">{`Cari Nomor Bill yang dimaksud menggunakan fitur search, kemudian klik Nomor Billnya`}</li>
      <li parentName="ol">{`Klik dropdown Action > Cancel `}</li>
      <li parentName="ol">{`Kemudian setelah muncul pop up konfirmasi cancel dokumen klik tombol yes`}</li>
    </ol>
    <p><strong parentName="p">{`Catatan`}</strong>{` : Untuk dokumen Vendor Bill yang dapat dibatalkan adalah dokumen yang statusnya “Open” dan belum ada sama sekali pembayaran. Tindakan ini juga akan membuat Jurnal yang terintegrasi akan berubah status menjadi Cancelled.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      